/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';
import api from '../../../services/API';
import './styles.css';
import { Link } from 'react-router-dom';
import Pagination from 'rc-pagination';

export default function PremiosLoja(props) {
  const dataStorage = JSON.parse(localStorage.getItem('integralmedica'));
  let paginaAnterior = null;
  if (dataStorage && dataStorage.paginaAnterior) {
    paginaAnterior = dataStorage.paginaAnterior;
    localStorage.removeItem('integralmedica');
  }

  const [premios, setPremios] = useState([]);
  const [total, setTotal] = useState(0);
  const [busca, setBusca] = useState('');
  const [pagina, setPagina] = useState(paginaAnterior || 1);
  const [limite, setLimite] = useState(48);
  const [buscando, setBuscando] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);
  const [subCategoriaSelecionada, setSubCategoriaSelecionada] = useState(
    props.subCategoriaSelecionada || []
  );
  const [showSubCategoria, setShowSubCategoria] = useState(false);
  const [clickSearch, setClickSearch] = useState(false);
  const [categoriaId, setCategoriaId] = useState('');
  const [categoriaLevel, setCategoriaLevel] = useState('');
  const [categoriaNome, setCategoriaNome] = useState('');

  const dispatch = useDispatch();
  const parceiroRedux = useSelector((state) => state.parceiro);

  const buscarPremios = async (categoria, subCategoria, levelCategoria) => {
    setCarregando(true);
    let resultado = [];

    if (subCategoria && levelCategoria) {
      resultado = await api
        .get(
          `/api/premios?pagina=${pagina}&limite=${limite}&ativo=1${`&busca=${busca}`}${
            subCategoria
              ? `&codigoCategoria=${subCategoria}&levelCategoria=${levelCategoria}`
              : ''
          }${props.tipo ? `&tipo=${props.tipo}` : ''}`
        )
        .catch((error) => {
          setSubCategoriaSelecionada([]);
          setPremios([]);
          setCarregando(false);
          console.log('Prêmios por subcategoria não encontrados', error);
        });
    } else if (clickSearch || (busca !== '' && !categoria)) {
      setCategoriaSelecionada([]);
      resultado = await api
        .get(
          `/api/premios?pagina=${pagina}&limite=${limite}&ativo=1${`&busca=${busca}`}${
            props.tipo ? `&tipo=${props.tipo}` : ''
          }`
        )
        .catch((error) => {
          setSubCategoriaSelecionada([]);
          setPremios([]);
          setCarregando(false);
          console.log('Prêmios por categoria não encontrados', error);
        });
    } else {
      resultado = await api
        .get(
          `/api/premios?pagina=${pagina}&limite=${limite}&ativo=1${`&busca=${busca}`}${
            categoria
              ? `&codigoCategoria=${
                  categoria ?? categoriaSelecionada[0]
                }&levelCategoria=${'1' ?? categoriaSelecionada[1]}`
              : ''
          }${props.tipo ? `&tipo=${props.tipo}` : ''}`
        )
        .catch((error) => {
          setSubCategoriaSelecionada([]);
          setPremios([]);
          setCarregando(false);
          console.log('Prêmios por categoria não encontrados', error);
        });
    }

    if (resultado) {
      if (busca) setShowSubCategoria(false);
      setPremios(resultado.data.premios);
      setTotal(resultado.data.total);
      setSubCategoriaSelecionada([]);
      setClickSearch(false);
    }
    setCarregando(false);
  };

  function handleSearch(e) {
    e.preventDefault();

    if (e.key === 'Enter') {
      setBuscando(true);
      setCategoriaId(null);
      setCategoriaLevel(null);
      setCategoriaNome(null);
      setCategoriaSelecionada([]);
      setSubCategoriaSelecionada([]);
      setShowSubCategoria(false);
      setClickSearch(true);
      buscarPremios();
      setBuscando(false);
    }
  }

  function handleSearchClick(e) {
    e.preventDefault();
    setBuscando(true);
    buscarSubCategorias(e.target.value);
    if (e.target.value) {
      buscarPremios(e.target.value);
    } else {
      buscarPremios();
    }
    if (e.target.value) setShowSubCategoria(true);
    if (!e.target.value) setShowSubCategoria(false);
    setBuscando(false);
  }

  const showDropdown = () => {
    document.getElementById('myDropdown').classList.toggle('show');
  };

  window.onclick = function (event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName('dropdown-content');
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  };

  const buscarCategorias = async () => {
    try {
      const response = await api.get('/api/premios/categorias');
      const cat = response.data.categorias;

      setCategorias(cat);
    } catch (error) {
      console.log('Erro ao buscar as categorias', error);
    }
  };

  const buscarSubCategorias = async (idCategoria) => {
    if (idCategoria) {
      const response = await api
        .get(`/api/premios/categorias?level=2&idCategoriaPai=${idCategoria}`)
        .catch((err) => console.log('Erro ao buscar a sub-categoria', err));
      const cat = response.data.categorias;

      setSubCategorias(cat);
    }
  };

  function openNav() {
    document.getElementById('mySidenav').style.width = '80%';
  }

  function closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  useEffect(() => {
    buscarPremios(
      categoriaId ?? '',
      subCategoriaSelecionada.idCategoria ?? '',
      subCategoriaSelecionada.level ?? ''
    );
    buscarCategorias();
    dispatch(atualizarSaldo(parceiroRedux.cpf));
  }, [pagina, limite]);

  useEffect(() => {
    document.title = 'Integralmédica - Loja';
    buscarPremios(
      categoriaId ?? '',
      subCategoriaSelecionada.idCategoria ?? '',
      subCategoriaSelecionada.level ?? ''
    );
  }, []);

  return (
    <Container className="mb-5">
      <div className="mt-3 align-self-start justify-content-center sticky-search">
        <div id="container-busca-geral">
          <div className="d-flex flex-column justify-content-between align-items-center container-busca">
            <h5 className="mb-3">BUSCAR PRODUTO</h5>
            <div className="d-flex flex-row align-items-center justify-content-start align-self-start mb-4 w-100 container-secaoDropdown">
              <div className="secaoDropdown w-100">
                <input
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  onKeyUp={handleSearch}
                  className="input-busca"
                  placeholder="Digite a palavra chave"
                />
                <button
                  className="btn-pesquisa mt-0"
                  type="submit"
                  onClick={(e) => {
                    setCategoriaId(null);
                    handleSearchClick(e);
                  }}
                >
                  BUSCAR
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between align-items-center container-busca-dois">
            <h5 className="mb-3">NAVEGUE POR CATEGORIA</h5>
            <div className="w-100 container-secaoDropdown-dois">
              <div className="secaoDropdown-dois w-100">
                <div className="dropdown">
                  <button
                    onClick={() => {
                      setBusca('');
                      showDropdown();
                    }}
                    className="dropbtn"
                  >
                    {categoriaId ? categoriaNome : 'Escolha uma categoria'}
                    <i className="fas fa-chevron-down"></i>
                  </button>
                  <div id="myDropdown" className="dropdown-content">
                    <button
                      onClick={(e) => {
                        setBusca(null);
                        setCategoriaSelecionada([]);
                        setCategoriaId(null);
                        setCategoriaLevel(null);
                        setCategoriaNome(null);
                        handleSearchClick(e);
                      }}
                      value={''}
                    >
                      Todas
                    </button>
                    {categorias.map((item) => {
                      if (
                        item.id !== 166 &&
                        item.id !== 2579 &&
                        item.id !== 1942 &&
                        item.id !== 1484 &&
                        item.id !== 2563
                      ) {
                        return (
                          <button
                            onClick={(e) => {
                              setCategoriaSelecionada([]);
                              setCategoriaId(item.idCategoria);
                              setCategoriaLevel(item.level);
                              setCategoriaNome(item.nome);
                              setCategoriaSelecionada([
                                item.idCategoria,
                                item.level,
                                item.nome,
                              ]);
                              setPagina(1);
                              handleSearchClick(e);
                            }}
                            key={item.id}
                            value={item.idCategoria}
                          >
                            {item.nome}
                          </button>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr-loja mt-0" />
          </div>
        </div>
        <hr className="hr-loja-desktop mt-0" />
      </div>
      <Row>
        {!carregando && showSubCategoria && (
          <Col lg={3}>
            <div id="sidebar">
              <div>
                <h6>Sub-categorias</h6>
                <div className="conteudo-subcategoria">
                  {subCategorias.map((item) => {
                    if (item.id !== 2552) {
                      return (
                        <button
                          onClick={() => {
                            const subCategoria = item.idCategoria;
                            const level = item.level;
                            setPagina(1);
                            setSubCategoriaSelecionada([
                              item.idCategoria,
                              item.level,
                              item.nome,
                            ]);
                            buscarPremios('', subCategoria, level);
                          }}
                          key={item.id}
                        >
                          {item.nome}
                        </button>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </Col>
        )}
        {!carregando && showSubCategoria && (
          <Col xs={12} className="container-sidenav">
            <span className="openFiltro" onClick={() => openNav()}>
              Filtros
            </span>
            <div id="mySidenav" class="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={() => closeNav()}
              >
                &times;
              </a>
              <h6>Sub-categorias</h6>
              <div className="conteudo-subcategoria-mobile">
                {subCategorias.map((item) => {
                  if (item.id !== 2552) {
                    return (
                      <button
                        onClick={() => {
                          const subCategoria = item.idCategoria;
                          const level = item.level;
                          buscarPremios('', subCategoria, level);
                        }}
                        key={item.id}
                      >
                        {item.nome}
                      </button>
                    );
                  }
                })}
              </div>
            </div>
          </Col>
        )}
        {carregando && (
          <Col xs={12}>
            <h6>Carregando...</h6>
          </Col>
        )}
        {!carregando && premios.length === 0 && (
          <Col xs={12}>
            <h6>Produto não encontrado.</h6>
          </Col>
        )}
        <Col>
          <Row>
            {!carregando &&
              premios.map((premio) => {
                return (
                  <Col
                    key={premio.id}
                    id={premio.id}
                    name="premio"
                    xs={12}
                    md={4}
                    lg={3}
                    className="mt-5"
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                      <Link
                        to={{
                          pathname: `/premio/${premio.id}`,
                          state: pagina,
                        }}
                      >
                        <img
                          alt={premio.nome}
                          className="w-100"
                          src={premio.diretorioFoto}
                        ></img>
                      </Link>

                      <span className="card-premio-nome">{premio.nome}</span>
                      <span>
                        <b>
                          {Number(premio?.pontos).toLocaleString('pt-BR')}
                          {` pontos`}
                          {premio.tipo !== 'credito' &&
                            premio.tipo !== 'recarga' &&
                            ` (+ Frete)`}
                        </b>
                      </span>
                      <Link
                        to={{
                          pathname: `/premio/${premio.id}`,
                          state: pagina,
                        }}
                      >
                        <button className="btn-resgatar mt-1">RESGATAR</button>
                      </Link>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} className="d-flex justify-content-center">
          <Pagination
            current={pagina}
            showSizeChanger={true}
            total={total}
            pageSize={limite}
            hideOnSinglePage
            locale="pt_BR"
            onChange={(current, pageSize) => setPagina(current)}
            onShowSizeChange={(current, size) => setLimite(size)}
          />
        </Col>
      </Row>
    </Container>
  );
}
